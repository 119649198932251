<!-- 表格
< <d-lable :tableData='[列表数据]' :columnData='[列表项]' @compile='[编辑函数]'></d-lable> />
:columnData '[列表项]'
columnData 的 columnType 是列功能项 click:可点击列
columnData 的 type 是按钮类型 compile:编辑,
                            remove:删除,
                            accredit:授权,
                            promotion:升级,
                            dimission:离职,
                            check:审核
columnData 的 positionTag 是开启标签

tableData 的 isBtnStatus 可以控制每一项列表的btn是否隐藏显示
tableData 的 isCompile 可以控制每一项列表的编辑btn 是否显示隐藏, 默认值为显示
             isRemove    删除
             isAccredit  授权
             isPromotion 升职
             isDimission 离职
             isCheck     审核
-->

<template>
  <el-table size="medium"
            class="el-table"
            v-bind="$attrs"
            v-on="$listeners"
            :data="tableData">
    <template v-for="(column,index) in columnData">
      <el-table-column v-if="column.type === 'selection'" 
                       type="selection"
                       width="55"
                       v-bind:key="column.index">
      </el-table-column>
      <el-table-column v-else-if="column.positionTag"
                       v-bind:key="column.index"
                       :prop="column.prop"
                       :label="column.label"
                       :width="column.width">
        <template slot-scope="scope">
          <span v-if="scope.row['position']">{{scope.row[column.prop]}} <el-tag>店长</el-tag> </span>
          <span v-else>{{scope.row[column.prop]}}</span>
        </template>
      </el-table-column>
      <el-table-column v-else-if="column.columnType === 'click'"
                       v-bind:key="index"
                       :prop="column.prop"
                       :label="column.label"
                       :width="column.width">
        <template slot-scope="scope">
          <span class="clickstyle"
                @click="clickType(scope.row)">{{scope.row[column.prop]}}</span>
        </template>
      </el-table-column>
      <slot v-else-if="column.slot" :name="column.prop"></slot>
      <el-table-column v-else-if="column.slotProp" 
                       :key="index"
                       :width="column.width"
                       :label="column.label">
        <template slot-scope="scope">
          <slot :name="column.prop" :row="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column v-else-if="column.prop === 'operate'"
                       v-bind:key="index"
                       :prop="column.prop"
                       :label="column.label"
                       :width="column.width">
        <template slot-scope="scope">
          <slot name="handle"
                :item="scope.row"></slot>
          <div class="operateClass clickstyle"
               v-if="scope.row.isBtnStatus || scope.row.isBtnStatus === undefined">
            <span v-if="column.type.compile && (scope.row.isCompile === undefined || scope.row.isCompile)"
                  @click="compile(scope.row)">编辑</span>
            <span v-if="column.type.remove  && (scope.row.isRemove === undefined || scope.row.isRemove)"
                  @click="removeEvent(scope.row)">删除 </span>
            <span v-if="column.type.accredit && (scope.row.isAccredit === undefined || scope.row.isAccredit)"
                  @click="accredit(scope.row)">授权</span>
            <span v-if="column.type.viewBtn && (scope.row.isAccredit === undefined || scope.row.isAccredit)"
                  @click="viewEvent(scope.row)">查看</span>
            <span @click="transfer(scope.row)" v-if="column.type.transfer && scope.row.state === 1 && scope.row.position"
                 >转移</span>
            <span v-if="column.type.promotion && (scope.row.isPromotion === undefined || scope.row.isPromotion)"
                  @click="positionChange(scope.row,'top')">升职</span>
            <span v-if="column.type.dimission && (scope.row.isDimission === undefined || scope.row.isDimission)"
                  class="dimission"
                  @click="positionChange(scope.row,'reduce')">离职</span>
            <span v-if="column.type.check && (scope.row.isCheck === undefined || scope.row.isCheck)"
                  @click="checkTime(scope.row)">审核</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-else
                       :min-width="column.width"
                       v-bind:key="index"
                       :prop="column.prop"
                       :label="column.label">
      </el-table-column>
    </template>
  </el-table>
</template>

<script>

export default {
  name: 'd-table',
  data () {
    return {

    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    columnData: {
      type: Array,
      required: true
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    /**
     * 转移btn
     */
    transfer (column) {
      this.$emit('transfer', column)
    },
    /**
     * 编辑btn
     */
    compile (column) {
      this.$emit('compile', column)
    },
    /**
     * 审核btn
     */
    checkTime (column) {
      this.$emit('checkTime', column)
    },
    /**
     * 授权btn
     */
    accredit (column) {
      this.$emit('accredit', column)
    },
    /**
     * 删除btn
     */
    removeEvent (column) {
      this.$emit('removeEvent', column)
    },
    /**
     * 升值/降职
     * flag 为 top 升值
     * flag 为 reduce 降职
     */
    positionChange (column, flag) {
      this.$emit('positionChange', column, flag)
    },
    /**
     * 查看btn
     */
    viewEvent (column) {
      this.$emit('viewEvent', column)
    },
    /**
     * 可点击列
     */
    clickType (column) {
      this.$emit('rowClick', column)
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.el-table {
  color: #999;
}
.clickstyle {
  color: #5c6be8;
  cursor: pointer;
}
.operateClass span {
  display: inline-block;
  margin: 0 5px;
  text-decoration: underline;
}
.operateClass span:first-child {
  margin-left: 0;
}
.el-tag {
  font-size: 12px;
  border: 1px solid #ccc;
  padding: 0 1px;
  border-radius: 5px;
  height: 22px;
  line-height: 22px;
}
.dimission {
  color: red;
}
</style>
